/* App.css */

/* Wrapper styles */
.wrapper {
  display: flex;
}

/* Sidebar styles */
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #333;
  color: #fff;
  padding-top: 50px;
}

.sidebar-header {
  padding: 20px;
}

.sidebar-header h3 {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 20px;
}

/* Navbar styles */
#content {
  margin-left: 250px; /* Adjust this value to match the width of your sidebar */
  padding: 20px;
}

.navbar {
  background-color: #f8f9fa;
}

.navbar-nav .nav-link {
  color: #333;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

/* Content styles */
.content {
  padding: 20px;
}
