/* Sidebar styles */
#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: black;
    color: #fff;
    padding-top: 20px; /* Adjust as needed */
}

.sidebar-header {
    padding: 0 20px; /* Add padding to the header */
}

.sidebar-logo {
    text-align: center;
    margin-bottom: 20px; /* Adjust as needed */
}

.sidebar-logo img {
    width: 80%; /* Adjust the width of the logo */
    height: auto;
}

.sidebar-links {
    padding-left: 20px;
}

.sidebar-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-links li {
    margin-bottom: 10px; /* Adjust as needed */
    margin-left: 20px;
}

.sidebar-logo {
    width: 80%; /* Adjust the width of the logo */
    height: auto; /* Maintain aspect ratio */
}

.sidebar-links a {
    color: #fff;
    text-decoration: none;
}

.sidebar-links a:hover {
    color: #007bff; /* Change the hover color as needed */
}

/* Sub-menu styles */
.sidebar-links ul ul {
    display: none; /* Hide sub-menu by default */
    padding-left: 20px; /* Indent sub-menu */
}

.sidebar-links li.open ul {
    display: block; /* Show sub-menu when parent is open */
}
